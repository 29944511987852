<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>Open VLC<br /><br /></p>
								<p>Click OPEN MEDIA<br /><br /></p>
								<p>Click Network<br /><br /></p>
								<p>Insert the required m3u and replace <strong>YOUR_</strong><strong>EMAIL/ PASSWORD</strong> with your details<br /><br /></p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<strong style="box-sizing: border-box; font-weight: bold">Live TV Channels</strong><br style="box-sizing: border-box" /><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong style="box-sizing: border-box; font-weight: bold"
											><span style="box-sizing: border-box; color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong style="box-sizing: border-box; font-weight: bold"
											><span style="box-sizing: border-box; color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong style="box-sizing: border-box; font-weight: bold"><span style="box-sizing: border-box; color: rgb(0, 0, 0)">/</span></strong
										>m3u8/livetv</a
									>
								</p>
								<p
									dir="ltr"
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br style="box-sizing: border-box" /><strong style="box-sizing: border-box; font-weight: bold">Movies&nbsp;</strong
									><br style="box-sizing: border-box" /><a
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<strong style="box-sizing: border-box; font-weight: bold"
										><span style="box-sizing: border-box; color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong style="box-sizing: border-box; font-weight: bold"><span style="box-sizing: border-box; color: rgb(0, 0, 0)">PASSWORD</span></strong
									>/m3u8/movies<br style="box-sizing: border-box" /><br style="box-sizing: border-box" /><strong style="box-sizing: border-box; font-weight: bold"
										>TV Shows (VERY big list. Don't use on old/small devices)</strong
									><br style="box-sizing: border-box" /><a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1 (Most new tv shows)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/6`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/6
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/7`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/7
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/8`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/8
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									(Oldest tv shows)
								</p>
								<p><br /></p>
								<p><br /></p>
								<p><strong>TV Guide/EPG URL</strong><br />Select Lite or Full url.</p>
								<p><br /></p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									Regular version – 7 day listing&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top" style="box-sizing: border-box; color: rgb(8, 18, 29)"></a
									><a
										:href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									>Use:
									<a :href="$store.state.brandInfo.tvGuidLink" style="box-sizing: border-box; color: rgb(8, 18, 29)">{{ $store.state.brandInfo.tvGuidLink }}</a>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									Lite version - 48h listing (For old/small devices)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top" style="box-sizing: border-box; color: rgb(8, 18, 29)"></a
									><a
										:href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									></a
									>Use:
									<a :href="$store.state.brandInfo.tvGuidLiteLink" style="box-sizing: border-box; color: rgb(8, 18, 29)"
										>{{ $store.state.brandInfo.tvGuidLiteLink }}
									</a>
								</p>
								<p><br /></p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'howDoIAddToVlcPlayer',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
